.numeric-range {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  margin: 0 150px;
  grid-row-gap: 10px;

  span {
    text-align: center;
  }

  .range-header {
    display: inline-block;
    margin: auto;
  }

  .range-header-max {
    display: inline-block;
    margin-left: 16px;

  }

  & input {
    text-align: center;
    height: 2.66rem;
    width: 9.16rem;
    margin: 0  1rem;
  }
}

.numeric-input {
  border-radius: 4px;
  box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.41);
  display: inline-block;
}

.input-label {
  width: 12px;
  display: inline-block;
}