@import "../../style/vars";
@import '../../style/amplify';

.from-left-appear {
  transition: 1000ms;
  transform: translateX(-100%);
}
.from-left-appear-active {
  transform: translateX(0);
}

.from-right-appear {
  transition: 1000ms;
  transform: translateX(100%);
}
.from-right-appear-active {
  transform: translateX(0);
}



.go-back {
  position: absolute;
}

.go-back-button {
  position: relative;
  left: 0px;
  top: 43.5px;
  background-color: transparent;
  color: $va-white-100;
  font-weight: 700;
  font-family: roboto;
  border-style: none;
  font-size: 16px;
}

.create-cognito-form {
  position:relative !important;
  width: 75%;
  margin: auto;
}


.flow-state{
  display: inline-block;
  font-size: 20px;
  background-color: $va-blue-100;
  padding: 0 5px;
}


.flow-state-text {
  display: inline-block;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  background-color: $va-blue-100;
  color: $va-white-100;
  padding: 0 10px;
}

.flow-state-text.right{
  float: right;
}

.flow-state-text.left{
  float:left;
}

.selected{
  color: $color3 !important;
}

//.flow-state-middle{
//  display: inline-block;
//  font-family: Roboto;
//  font-size: 20px;
//  color: $va-white-100;
//  background-color: $va-blue-100;
//  padding: 0 5px;
//}

.create-account-container{
  height: 100px;
}

.flow-state-container{
  height: 8px;
  border-bottom: 1.5px solid white;
  max-width: 550px;
  text-align: center;
  margin:auto;
}


