@import '../../style/style';
@import "bootstrap";

.comp-assist{
   hr.checkbox-group-line{
    background: black;
    margin: 5px 0;
  }

  .form-button {
      width:100%;
  }
  @include media-breakpoint-up(sm) {
    .form-button {
      max-width: 107px;
    }
  }
}


