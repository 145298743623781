@import '../../style/style';
@import '../../style/vars';
@import '../../style/mixin';

.va-modal {
  @include absolute-center;
}

.container-wrap {
  display: flex;
  flex-direction: column;
}

.close-button-wrap {
  align-self: flex-end;
  margin: 10px;
}

.children-wrap {
}

.close-button-wrap {
  max-width: 75px;
}
