@import '../../style/style';
@import '../../style/vars';

.table-tab-container {
  display: flex;
  flex-direction: row;
  gap: 1%;
  height: 100%;
}

.table-tab-wrap {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
