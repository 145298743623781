@import '../../style/vars';

.filter-container {
  display: grid;
  grid-template-rows: min-content auto 75px;
  background-color: $container-background-color;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  position: relative;
  height: 40rem !important;

  .filter-container-errors {
    margin-top: 20px;
    margin-left: 5px;
    color: red;
  }
}

.filter-container-filters {
  overflow: auto;
  padding: 1rem 1rem;

  > *+* {
    margin-top: 1rem;
  }
}

.filter-save-input-container {
  display: flex;
  flex-direction: row;
  padding: .5rem;

  & input {
    margin-left: 1rem;
    width: 99%;
    border: none;
  }
}

.save-button-background {
  background-image: url(../../img/filter-save-disabled.svg);
}

.edit-button-background {
  background-image: url(../../img/filter-save-disabled.svg);
}

.container-save-button {
  background-repeat: no-repeat;
  background-size: 2.16rem 2.16rem;
  background-color: transparent;
  border: none;
  padding: 1rem;

  &:hover:not(.disabled) {
    background-image: url(../../img/filter-save-hover.svg);
  }

  &.disabled {
    background-image: url(../../img/filter-save-disabled.svg);
  }
}

.filter-container-inactive {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}
