@import '../../style/style';
@import '../../style/vars';


.comps-table-container {
  display: grid;
  grid-template-rows: 4rem auto;

  .tabs {
    width: 1000px;
    min-width: 480px;
  }

  .toolbar-table-wrap{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: $color3 $va-white-100  $va-white-100 $color3;
    border-style: solid;
    border-width: 1px;
  }
}
