.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  & .text-wrap {
    flex-grow: 4;
    justify-self: left;
  }

  & .arrows-wrap {
  }

  & .delete-button {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;

    & button {
      align-self: flex-end;
      width: min-content;
    }
  }
}
