// fonts
$font-family-robo: Roboto;

$font-size-small: 1rem;
$font-size-medium: 1.45rem;

$font-style-default: normal;
$line-height-default: 1.33rem;

$letter-spacing-default: 0;
$font-weight-default: 400;
/////////////

// containers
$container-background-color: rgba(255, 255, 255, 1);
$container-border-radius: 4px;
$container-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
////////////

// tables
$table-header-shadow: 0px 4px 0px rgba(0, 0, 0, 0.01);
////////////

// colors
$va-blue-100: #061F61;
$va-blue-75: #4661A6;
$va-blue-50: #8395C4;
$va-blue-35: #A9B5D6;
$va-blue-10: #E6EAF3;
$va-title-blue:  #082C89;


$va-secondary-100: #EEAD4C;
$va-secondary-75: #F2C279;
$va-secondary-50: #F6D6A5;
$va-secondary-35: #F9E2C0;
$va-secondary-25: #FBEBD2;
$va-secondary-10: #FDF7ED;


$va-accent-100: #59C2E3;
$va-accent-75: #83D1EA;
$va-accent-50: #ACE1F1;
$va-accent-25: #D6F0F8;
$va-accent-10: #eef9fc;

$va-grey-100: #2E2E2E;
$va-grey-75: #626262;
$va-grey-50: #969696;
$va-grey-35: #B6B6B6;
$va-grey-25: #CBCBCB;

$va-black-100: #000000;
$va-black-75: #404040;
$va-black-50: #808080;
$va-black-25: #BFBFBF;

$va-white-100: #FFFFFF;

$va-table-stripe: #ECF0F5;

$datepicker-border-color: #061F61;

$color3: #eead4c;
$color2: #4ca9ee;

//////////////

// search bar
$search-bar-height: 5.16rem;
/////////////

// nav bar
$nav-bar-height: 5vh;
/////////////

// page
$page-height: 100vh - $nav-bar-height;
/////////////

// comps tab
$comps-tab-width: 48px;
/////////////

// z-indexes
$z-index-sticky-table-header: 2;
$z-index-comps-tab: 2;
$z-index-search-bar: 3;
$z-index-portrait-tab: 4;
$z-index-calendar: 5;
$z-index-nav-bar: 98;
/////////////


