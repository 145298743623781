@import '../../style/vars';

.criteria-category-container {
  background-color: $container-background-color;
  border-radius: $container-border-radius;
  overflow: auto;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  display: none;

  &.show {
    transition: height 0.2s ease-out;
    height: 20rem;
    padding: 1.5rem;
    margin-top: 3rem;
    position: absolute;
    z-index: 5;
    display: block;
  }
}

.criteria-category-container-grid {
  display: grid;
  grid-template-columns: repeat(2, 16.5rem);
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-items: left;
  justify-content: space-evenly;
  align-content: space-evenly;
}


