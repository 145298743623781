@import '../../style/vars';

.welcome-link{
  margin-left: 10px;
  margin-top: 10px;
  font-family: Roboto;
  font-weight: 700;
}

.youtube-link{
  color: $va-secondary-100;
  font-family: Roboto;
  font-weight: 700
}
.filter-page-search-bar {
  grid-area: searchbar;
  min-height: 110px;
}

.filter-page-filters {
  grid-area: filters;
  padding: 0 0 0 1rem;
}

.filter-page-saved-filters {
  grid-area: savedFilters;
  position: relative;
  overflow: auto;
  background-color: $container-background-color;
  box-shadow: $container-box-shadow;
  border-right: $container-border-radius;
  border-radius: .5rem;
}

.filter-page-table {
  grid-area: table;
  grid-column-start: left-component-start;
  grid-column-end: right-component-end;
  grid-row-start: table-start;
  grid-row-end: -1;
  padding: 1rem 0 0 0;
}

.filter-page-after-search {
  grid-template-columns: [left-component-start] 2fr[left-component-end right-component-start] 2fr[right-component-end];
  grid-template-rows: [row-component-start] 400px[row-component-end table-start];
}

.filter-page-search {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr 3fr;
  grid-template-areas: "searchbar filters"
                       "savedFilters filters";
  z-index: $z-index-search-bar;
}

.filter-page {
  height: 100%;
}


.h_line {
  width:95%;
  height:1px;
  background: $va-blue-100;
  margin: -317px 0px 0px 0px;
  z-index: 2;
  justify-self: center;
}
