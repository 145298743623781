@import '../../style/_vars';

.informational-main-about-us {

  .profile {
    margin-bottom: 3.5rem;

    .avatar {
      max-height: 21rem;
      max-width: 300px;
      margin: auto;
    }

    .bio {
      line-height: 3.17rem;

      h2 {
        color: $va-blue-100;
        font-size: 2.00rem;
        font-weight: 400;

      }

      p {
        font-size: 1.5rem;
        color: $va-black-100;
      }
    }
  }
}
