@import '../../style/vars';

.search-criteria-bar {
  z-index: 2;
  display: inline-block;
}

.search-criteria-bar-textarea {
  display: flex;
  flex-direction: row;
  margin: 1rem;

  input {
    width: 100%;
    border: none;
    margin-left: 1rem;
  }
}
