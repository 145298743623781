.comps-multiples-table {
  .va-table {
    td div {
      display: flex;
      align-items: center;
    }

    td div, th {
      width: 100px;
    }

    .line {
      border-top: 2px solid black;
    }
  }
}
