@import '../../style/vars';
@import '../../style/style';

.saved-comps-groups-container {
  overflow: auto;
  padding: 10px;

  .saved-comps-groups {
    display: grid;
    padding: 1rem 2rem;
    grid-template-columns:
          [start-date-created]
          1fr[end-date-created]
          .25fr[start-name]
          1fr[date-picker]
          1fr[button1]
          .5fr[button2]
          .5fr[end];
    align-items: center;
    justify-items: left;
    white-space: nowrap;
    overflow: auto;

    & .date {
      grid-column: start-date-created/end-date-created;
    }

    & .name {
      grid-column: start-name/date-picker;
    }

    & .date-picker {
      grid-column: date-picker/button1;
    }

    & .button1 {
      grid-column: button1/button2;
    }

    &.button2 {
      grid-column: button2/end;
    }
  }
}

.header-name {
  margin-left: 10px;
  border-bottom: 1px solid $va-blue-100;
  padding-bottom: 5px;
}


.h_line_saved_comps {
  width:98%;
  height:1px;
  margin: 10px 0px 0px 5px;
  background-color: $va-blue-100;
}
