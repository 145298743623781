@import '../../style/style';
@import '../../style/vars';

.filter-table-container {
  background-color: $container-background-color;
  box-shadow: $container-box-shadow;
  border-right: $container-border-radius;
  border-radius: .5rem;
  display: inline-block;
  //max-height: 100vh;
}

.filter-table-toolbar {
  padding: 1rem;
  display: grid;
  grid-template-columns: 100px 200px;
  left: 0;

  & .filter-table-toolbar-label {
    color: $va-blue-100;
  }
}


.filter-table {
  border-collapse: separate;
  width: 100%;
  position: relative;
  border-width: 0;
  border-spacing: 0;

  th{
    &.highlight{
        background-color: $va-secondary-25 !important;
        &:before,
        &:after{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: white;
        }
    }
  }


  & tbody, thead {
    & tr, td {
      text-align: left;
      max-width: 40rem;
      min-width: 7rem;

      & tr.add-to-comps,
      td.add-to-comps {
        max-width: none;
        min-width: 0;
        width: min-content;
        padding: 1rem;
      }
    }


  }



  & thead {
    top: 0;
    position: sticky;

   th {
      position: relative;
      background-color: $container-background-color;
      box-shadow: $table-header-shadow;
      height: 4rem;
      padding: 0 2rem;
      border-bottom: 1px solid black;

      & svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &.up {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  & tbody {
    & td {
      padding: .5rem 2rem;
      white-space:nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
    }
    td:hover{
    overflow:visible;
      white-space:normal;
    }

    & tr:nth-child(odd) {
      background-color: $va-table-stripe;
    }
  }
}
