@import '../../style/vars';

.category-expansion-toggle {
  width: 1rem;
  text-align: center;
  line-height: 0;
}

.category-expansion-toggle:hover {
  cursor: pointer;
}


