@import './style/vars';

html {
    font-size: 12px;
}

body {
    background: #F1F1F2;
    margin: 0;
    font-family: $font-family-robo;
    font-size: $font-size-small;
    font-style: $font-style-default;
    line-height: $line-height-default;
    letter-spacing: $letter-spacing-default;
    font-weight: $font-weight-default;
}

button {
    font-family: $font-family-robo;
    font-size: $font-size-small;
    font-style: $font-style-default;
    line-height: $line-height-default;
    letter-spacing: $letter-spacing-default;
    font-weight: $font-weight-default;
}

