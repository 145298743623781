@import '../../style/vars';
@import '../../style/style';

.va-navbar {
  background-color: rgba(8, 44, 137, 0.05);
  padding-right: 10px;
  padding-left: 10px;

  .nav-bar-logo {
    width: 9rem;
  }

  .nav-item {
    text-align: right;
    padding-top: 25px;

    .va-link {
      white-space: nowrap;
      color: $va-title-blue;
      text-decoration: none;

      &.signup, &.login {
        padding: .5rem 2rem;
        display: block;
        text-align: center;
      }

      &.signup {
        background-color: $va-secondary-100;
        color: black;
      }

      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .va-link:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .va-dropdown {
    border-radius: 30px !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    .nav-signout {
      display: grid;
      grid-template-columns: min-content auto min-content;
      grid-template-rows: min-content;
      align-items: center;

      .user-info {
        display: grid;

        .user-name,
        .user-email {
          color: $va-white-100;
        }
      }
    }

    .drop-link {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      text-decoration-line: none;
      color: $va-white-100;
      white-space: nowrap;
      text-align: center;
    }

    .white-line {
      height: 1px !important;
      color: $va-white-100;
      margin: 10px 10px;
      opacity: 1;
    }
  }

}
