@import '../../style/vars';


//.small-inline-button-container{
//  display: inline-block !important;
//}

.button.primary.subscribe{
  margin: 0 !important;
  height: 50px;
  width: 220px;
  font-weight: 700;
}

.x-button{
  //padding: 5px !important;
  width: 30px;
  height: 30px;
}
.small-inline-button-left{
  margin:0 !important;
  height: 30px;
  width: 70px;
  margin-right: 10px;
  display: inline-block !important;
}

.small-inline-button-right{
  margin:0 !important;
  height: 30px;
  width: 70px;
  margin: 0;
  display: inline-block !important;
}
.inline-button{
  margin:0 !important;
  height: 30px;
  width: 150px;
}

.right-inline-button{
  margin-bottom:0 !important;
  float: right;
}
.button {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  border: none;
  border-radius: 4px;

  .async-loader {
    margin: auto !important;
  }
  &.primary {
    background: $va-secondary-100;
    border-radius: $container-border-radius;
    color: $va-black-100;

    &.disabled {
      background: $va-secondary-25;
      color: $va-grey-25;
    }

    &.disabled:hover {
      background: $va-accent-25;
      cursor: auto;
    }

    &:hover:not(.disabled) {
      background: $va-secondary-75;
      cursor: pointer;
    }
  }

  &.secondary {
    background: transparent;
    color: $va-grey-100;

    &.disabled {
      color: $va-grey-25;
    }

    &:hover:not(.disabled) {
      text-decoration: underline;
      cursor: pointer;
    }
  }


  &.icon-button {
    display: flex;
    align-items: center;
    justify-content: left;
    height: min-content;
    width: min-content;

    & svg {
      flex-grow: 1;
    }
  }

  &.save-button {
    & svg {
      fill: $va-blue-50;
    }

    &:hover:not(.disabled) {
      & svg {
        fill: $va-blue-100;
      }
    }
  }

  &.circle-button {
    padding: 0;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: $va-secondary-100;
    color: $va-black-100;
  }
}

.icon-left-button {
  & :hover{
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 100%;

  & svg {
    flex-grow: 1;
  }

  & button {
    flex-grow: 3;
    text-align: left;
  }

  &.outlined {
    & svg {
      padding-left: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
    border: solid 1px;
    border-radius: 30px;
    color: black;
  }
}
