@import '../../style/vars';


.va-accordion{
  margin-bottom: 3.5rem;
  font-family: Roboto;
  color: black;
}

// this class styles the headings in the accordion dropdowns
.accordion-button {
  font-size: 1.75rem;
  color: $va-blue-100;
  font-weight: 400;
  margin-bottom: 25px;
  font-family: Roboto;
  margin-bottom: 0;
}

// this class styles the accordion when it is clicked
.accordion-button:not(.collapsed) {
    background-color: white;
    color: $color3;
}

.accordion-body {
  padding:2.5rem;
  overflow: auto;
}

.accordion-button:focus {
    /* z-index: 3; */
    background-color: white;
    border: none;
    outline: 0;
    box-shadow: none;
}

.figure-image{
  width: 100%;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  border: black;
  border-style: solid;

}

.figure-caption {
  font-size: 1.4rem !important;
  color: black !important;
  margin-bottom: 2.0rem;
  padding-left: 10px;
  line-height: 2.0rem;
}

.figure-number {
  font-weight: bold;
}

.accordion-p {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 1.5rem;
}

.docs-icon{
  height:40px;
  padding-right: 20px;
}

.ins-list{
  width: 50%;
}