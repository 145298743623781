@import '../../style/vars';

.comps-conclusion-table {
  .position-calculations {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    padding-top: 1rem;

    .calculations-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .padding {
        padding: 0 3rem;
      }
    }
  }

  .divider {
    border-bottom: 2px solid black;
  }

  .va-table{
    .operator {
      font-weight: bold;
      padding-right: 12px;

      &.blank {
        padding-right: 20px;
      }
    }



    input {
      border: solid $va-blue-100 1px;
    }
  }

  & .va-table tbody.conclusion-table-totals {
    tr {
      background-color: transparent;
    }
  }
  .red {
    color: red;
  }

}
