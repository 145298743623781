@import '../../style/style';
@import '../../style/vars';
@import '../../style/mixin';

.table-toolbar {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;

  & .left {
    display: inline-block;
  }

  & .right {
    display: inline-block;
    background-color: $color3;
    padding: 5px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
  }
}

.table-title {
  position:relative;
  color: $va-blue-100;
  text-align: left;
    & div {
      padding: 1rem;
    }
}

.table-subtitle {
  font-size: 12px;
}
