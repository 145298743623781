.list {
  & ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: none;
  }

  & table {
    width: 100%;
    position:relative;
    overflow:auto;
    border-collapse: collapse;
  }


  & td {
    border: none;
    border-bottom: 1px solid rgb(179, 177, 177);
    padding: 8px;
  }

  & th{
    font-size: 1.45rem;
    font-style: normal;
    line-height: 1.33rem;
    letter-spacing: 0;
    font-weight: 400;
    color: #061F61;
    padding: 8px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #061F61;
    position: sticky;
    top: 0;
    background-color: white;
  }
}

