@import '../../style/vars';


.filter-state-container {
  background: $va-blue-10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin: .5rem 0;
  max-height: 20rem;
  overflow: hidden;
}

.Disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.filter-header {
  display: grid;
  grid-template-columns: [start-grip] .5fr [end-grip] 1fr[start-filter-name] 1fr[start-count] 1fr [start-edit] 1fr[start-apply] 1fr[start-trash];
  padding: 5px;
  vertical-align: middle;

  .async-loader {
    margin-left: 0;
  }
}

.filter-move-arrows {
  align-self: center;
  position: relative;

  & button {
    display: flex;
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.filter-trash {
  width: 1.25rem;
  height: 1.25rem;
}

.filter-trash-button {
  align-self: center;
  border: none;
  background: none;
}


.filter-controls {
  padding-bottom: 1.66rem;
  max-height: 15rem;
}




