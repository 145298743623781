@import '../../style/style';
@import '../../style/vars';
@import 'bootstrap';

$color3: #eead4c;

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.underlined {
  border-bottom: 1px solid $va-grey-35;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

}

.right-justify {
  justify-self: end;
  color: $va-accent-100;
}

.details-style {
  margin-left: 1rem;
  margin-bottom: 1.6667rem;
  font-size: 1.1667rem;
}

.card-details {
  display: inline-block;
  margin-right: 55px;
}

.account-page {
  .button {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .account-page-container {
    overflow-x: auto;
    min-height: 60rem;
    position: relative;

    .account-page-nav {
      border-right: 1px solid #000;

      ul {
        list-style: none;

        li {
          min-width: 200px;
          font-size: 14px;
          font-weight: 500;

          .selected {
            color: $va-accent-100;
          }

          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }


    @include media-breakpoint-down(lg) {
      .account-page-nav {
        border-right: none;
        border-bottom: none;
      }
    }
  }
}

.read-only {
  color: #969696;
  cursor: default;
}

.note {
  margin-left: 10px;
}

.email {
  color: $va-blue-100;
}


.modal {
  width: 550px;
  height: 300px;

  .button {
    background-color: $color3;
    color: black;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .container-wrap {
    padding: 4rem;
    height: 100%;

    .children-wrap {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: max-content 100%;
      justify-items: center;

      .invite-user {
        display: flex;
        align-items: center;
      }
    }
  }
}

.eye-button {
  display: inline;
  margin: 5px;
}

.eye-button:hover {
  cursor: pointer;
}

.form-input {
  margin-bottom: 1.5rem;

  & input, select {
    display: block;

  }
}

.error-details {
  position: relative;
  bottom: 3rem;
  color: red;
}

.relative-div {
  position: relative;
}

amplify-require-new-password {
  --box-shadow: 0;
}

.completion-message {
  display: inline;
  color: $va-blue-100;
  margin-left: 10px;
}
