.criterion-pin {
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.criterion-label {
  height: 1rem;
  margin-left: 30px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
}
