@import '../../style/style';
@import '../../style/vars';

$width: 4rem;
$height: 15rem;

.comps-tab {
  display: inline-block;
  background-color: $va-secondary-100;
  position: relative;
  width: $width;
  height: $height;
  border-radius: 5px 0 0 5px;
  box-shadow: $container-box-shadow;

}

.comps-tab-wrap {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: $va-black-100;
  position: absolute;
  white-space: nowrap;
  transform-origin: top left;
  bottom: 0;
  transform: rotate(-90deg) translateY(50%);
  align-items: center;
}

.comps-tab-comps-group {
  min-height: $height;
  width: 0;
  transition: width 100ms;
}

.expanded {
  width: 25rem;
}

.comps-tab-flyout {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  z-index: $z-index-comps-tab;
}

.page-comps-tab {
  position: fixed;
  right: 0;
  top: 5rem;
  z-index: 3;
}


