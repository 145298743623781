@import '../../style/_vars';

.p-header{
  font-size: 1.5rem;
  color: $va-blue-100;
  font-weight: 400;
  text-align: center;
}

.p-header-1{
  font-size: 1.5rem;
  color: $va-blue-100;
  font-weight: 400;
}

.tc-h1 {

  font-size: 2.0rem;
  color: $va-blue-100;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
}

.modified {
  font-size: 1.5rem;
  color: $va-blue-100;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
}
.top-bottom-margin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
