@import "../../style/vars";

.footer {
  background-color: $va-blue-100;
  color: $va-white-100;

  .footer-logo, .nav-item, .nav-link, a {
    color: white;
  }

  a {
    display: block;
  }

  h3 {
    margin-bottom: 0;
  }

  .navbar {
    .nav-item {
      h3 {
        margin-bottom: 1rem;
      }

      .nav-link {
        text-decoration: underline;
      }
    }
  }
}
