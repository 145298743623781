@import "../../../style/vars";


h3 {
      margin-bottom: 2.5rem;
    }

.bold{
  font-weight: bold;
}

p.underlined{
  border-bottom: solid 1px black;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.overlined{
  border-top: solid 1px black;
  padding-top: 1.5rem;
}

.order-summary {
  //width: 100%;
  border: solid 2px $color2;
  border-radius: 5px;
  padding: 3.33rem 3.33rem 4.5833rem;
  margin: 0 50px 50px 50px;

  .order-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 3rem;
    font-size: 14px;
    margin-bottom: 3rem;

    span {
      font-size: 1rem;
      font-weight: 500;
    }

    > :last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .left, .right {
      //margin-bottom: .8rem;

    }

    .left {
      p {
        display: inline;
      }

    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

    }
  }
}
