@import "../../style/vars";

.subscription-updater-modal {
  .va-modal {
    width: 350px;
    height: 350px;

    .children-wrap {
      display: grid;
      align-items: center;
      width: 100%;
      height: 100%;
      grid-template-rows: 100px 100px;
      z-index: 1;
      .text {
        text-align: center;
        font-size: 1.5rem;
        color: $va-blue-100;

      }
    }
  }
}
