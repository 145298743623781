@import '../../style/vars';

.search-criteria-container {
  width: 100%;
  background-color: $container-background-color;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  height: 4rem;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;

  //display: table-cell;
}

.search-criteria-wrap {
  //margin: 1rem;
  display: inline-block;
  //&.with-background {
  //  box-shadow: $container-box-shadow;
  //  background-color: $container-background-color;
  //}
}

.search-criteria-wrap-flap-container {
  //display: grid;
  //grid-template-columns: [start-flap] 1fr[end-flap start-pinned-filters] 4fr[end];
}

.filter-flap {
  //grid-column: [start-flap]/[end-flap]
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

.pinned-criteria-container {
  //padding-top: .5rem;
  //grid-column: [start-pinned-filters]/[end];

}

.search-criteria-container-categories {
  //display: flex;
  //flex-direction: column;

}
