@import '../../style/vars';

.table-column-editor {
  width: 50vh;
  height: 50vh;
  min-height: 40rem;
  min-width: 40rem;
  display: grid;
  grid-template-rows: 1fr 1fr 10fr;

  & .editor-title {
    grid-row: 1/2;
    justify-self: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
    & svg {
      height: 27px;
      width: 27px;
    }
  }

  & .editor-header {
    grid-row: 2/3;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    & div {
      flex-grow: 1;
    }
  }

  & .editor-body {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1rem 1rem;
    overflow: hidden;

    & .editor-body-item-wrap{
      overflow: auto;
      flex-grow: 1;
      border: 1px solid black;
      border-radius: $container-border-radius;
      width: 100%;
    }
  }
}


