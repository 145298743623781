@import '../../style/style';
@import '../../style/vars';

.container-save {
  box-sizing: border-box;
  border-radius: 20px;
  border: none;
  padding: 1rem;
}


.container-save-input {
  display:flex;
  flex-direction: row;
  padding: .5rem;

  & input {
    margin-left: 1rem;
    width: 99%;
    border:none;
  }
}

.container-save-clicked {
  border: 1px solid $va-blue-100;
  border-radius: 1.66rem;
}

.container-save-not-clicked{
  border: 1px solid $container-background-color;
  border-radius: 1.66rem;
}

.container-save-button {
  background-image: url(../../img/filter-save-disabled.svg);
  background-repeat: no-repeat;
  background-size: 2.16rem 2.16rem;
  background-color: transparent;
  border:none;
  padding: 1rem;

  &:hover:not(.disabled) {
    background-image: url(../../img/filter-save-hover.svg);
  }

  &.disabled {
    background-image: url(../../img/filter-save-disabled.svg);
  }
}
