@import '../../style/style';

.login-transition-appear {
  transition: 1000ms;
  transform: translateX(100%);
}

.login-transition-appear-active {
  transform: translateX(0);
}


.signup-logo {
  margin: -40px 0px 20px 0px;
  width: 15rem;
}

.auth-error {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index:101;
  background-color: white;
  color: $va-blue-100;
  font-family: Roboto;
}

.login-form {
  position:relative !important;
  height: 100%;
  width: 100%;
  background-color: $va-blue-100;
  background: url("../../img/background_img_blue.png");
  background-size: cover;
}

.login-form-1 {
  position:relative !important;
  margin: auto;
  width: 30%;
}

.login-page {
  background-color: $va-blue-100;
  width: 100%;
}


.amplify-header {

  color: $va-blue-100;
  font-size: 20px;
  text-align: center;
}

.form-section-header{
  font-size: 20px !important;
}

.welcome-message {
margin: 5px 0 30px 0;
//display: flex;
justify-content: center;
font-size: 25px;
// text-transform: uppercase;
//letter-spacing: 5px;
}

.toast {
position: relative !important;
}
