@import '../../style/style';
@import '../../style/vars';

.va-table {
  border-collapse: separate;
  width: 100%;
  position: relative;
  border-width: 0;
  border-spacing: 0;


  tbody, thead {
    tr, td {
      text-align: left;
    }

    tr.add-to-comps-header,
    td.add-to-comps-cell {
      max-width: none;
      min-width: 0;
    }
  }

  thead {
    position: sticky;
    top: 0;

    th {
      background-color: $container-background-color;
      box-shadow: $table-header-shadow;
      height: 4rem;
      padding: 0 2rem;
      border-bottom: 1px solid black;


      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .up {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  tbody {
    td {
      padding: .5rem 2rem;
    }

    tr:nth-child(odd) {
      background-color: $va-table-stripe;
    }
  }

}
