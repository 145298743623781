@import '../../style/style';
@import '../../style/vars';

.left-border {
  border-left: 2px solid black;
}

.bottom-border {
  border-bottom: 2px solid black;
}

.align-right {
  padding-left: 40px !important;
}


.sticky-header {
  position: sticky;
}


.financial-highlights {
  height: 100%;

  .input-wrap {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-left: 1rem;
  }

  .financial-highlights-tables {
    table.va-table {
      table-layout: fixed;
      grid-row: 2/-1;
      grid-column: 2/-1;

      th, td {
        height: 60px;
        //position: relative;
        word-wrap: break-word;
        white-space: normal;
      }

    }

    .zero-opacity {
      opacity: 0;
    }

    .tables-left {
      grid-row: 2/-1;
      grid-column: 1/2;
      position: sticky;
      left: 0;
      z-index: $z-index-sticky-table-header;
      background-color: $container-background-color;

      th {
        text-decoration: underline;
        text-align: left;
        padding-left: 1rem;
      }
    }

    .table-headers-section {
      grid-row: 1/2;
      grid-column: 1/-1;
      position: sticky;
      top: 0;
      background-color: $container-background-color;
      z-index: $z-index-sticky-table-header;
      overflow-y: visible;
    }

    .tables-middle {
      grid-column: 2/-1;
      grid-row: 2/-1;

      table.va-table {
        .table-header {
          position: sticky;
          top: 0;
        }

        th, td {
          width: 150px;
          position: relative;
        }

        th {
        }
      }

    }

  }
}


