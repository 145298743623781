.ticker-picker {
    min-height: 300px;
    min-width: 400px;
    height: 50vh;
    overflow: auto;
    padding: 0 20px 0 20px;

}

.ticker-item{
    padding: 5px;
}
.ticker {
    width: 25%;
}
