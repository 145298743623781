@import "../../style/vars";




.form-input {
  input, select {
    width: 250px;
    padding: 1.1667rem 1.5rem;
    margin: 0.3333rem 0 1rem 0;
    border: 1px solid $va-grey-50;
    border-radius: 4px;
    outline: none;
    font-size: 1rem;
  }
}

.va-font{
  font-family: Roboto !important;
}

.update-error {
  color: #FF0000;
  margin-left: 2rem;
  margin-bottom: 0rem;
  margin-top: 10px;

}

.done-message {
  color: $va-blue-100;
  margin-left: 2rem;
  margin-bottom: 0rem;
  margin-top: 10px;
}

.card-element {
    border: 1px solid $va-grey-50;
    border-radius: 4px;
    padding: 14px 18px;
    width: 300px;
    background-color: #fff;
    margin-bottom: 38px;
}

.update-page {
    margin-left: 1rem;
    margin-bottom: 0rem;
}

.update-button {
  justify-self: right;
  align-self: start;
}


.coupon-applied {
  color: $color3;
  font-weight: bold;
}

.coupon-failed {
  color: #FF0000;
}

.update-card-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#form {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  width: 100%;
  color: black;

  h3 {
    margin-bottom: 2.5rem;
    text-align: left;
    font-size: 21px;
    font-weight: bold;
  }
  h4 {
    font-size: 1.1667rem;
    margin-bottom: 1rem;
  }
  .privacy-policy {
    background-color: $va-white-100;
    border: solid 1px #99A0B4;
    border-radius: 5px;
    padding: 1rem;
    height: 13.8333rem;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 2.66rem;
    font-family: Roboto;
    text-align: left;
    h3 {
      font-size: 1.16rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .agree {
    margin-bottom: 3.1667rem;
    label {
      cursor: pointer;

    }

    input{
      float: left;
      width: 1rem;
      border: 1px solid $va-grey-50;
      transform: translate(0px, 3px);
      appearance: none;
      padding: 6px;
      border-radius: 3px;
      margin-right: 1rem;
      cursor: pointer;
    }
    input:checked {
      background-color: $va-accent-100;
      border: 1px solid $va-accent-100;
      color: #99a1a7;
    }
    input:checked:after {
      content: " ";
      background: url(../../img/input-check.svg);
      width: 10px;
      height: 7px;
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 1px;
      color: #99a1a7;
    }
    label {
      display: block;
      padding-left: 2.2rem;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: normal;
      line-height: 16px;
      a {
        color: #000;
      }
    }
  }
  .referral {
    margin-bottom: 3.1667rem;
    max-width: 300px;
    label {
      cursor: pointer;
    }
    input {
      border: 1px solid $va-grey-50;
      padding: 6px;
      border-radius: 3px;
      margin-right: 1rem;
      cursor: pointer;
      width: 100%;
      margin-bottom: 1rem;
    }
    label {
      display: block;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: normal;
      line-height: 16px;
    }
  }
  #submit,
  #submit:hover {
    background: $va-accent-100;
  }
  .back {
    float: left;
  }
  .error-details {
    color: red;
    margin-bottom: 1rem;
  }
  .summary {
    h3 {
      margin-bottom: 2.5rem;
    }
    p.underlined{
      border-bottom: solid 1px black;
      padding-bottom: 0.8em;
      margin-bottom: .8rem;
    }
    .order-summary-sign {
      background-color: $va-white-100;
      width: 100%;
      border: solid 2px $color2;
      border-radius: 5px;
      padding: 3.33rem 3.33rem 4.5833rem;
      .order-info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 3rem;
        span {
          font-size: 1rem;
          font-weight: 500;
        }
        > :last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .left, .right {
          padding-bottom: 1rem;
          margin-bottom: .8rem;
          border-bottom: solid 1px black;
        }
        .left {
          p {
            display: inline;
          }

          p:first-child {
            font-size: 1.3333rem;

          }
          p:nth-child(2) {
            font-size: 0.6667rem;
            font-weight: 300;
            margin-left: 5px;
            margin-bottom: .75rem;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            margin-bottom: 1rem;

          }
          p:nth-child(2) {
            font-size: 1.1667rem;
            font-weight: 300;
          }
        }
        #code, #code-value {
          font-weight: normal;
          margin-bottom: .5rem;
        }
      }
      .promo {
        font-family: Roboto !important;
        p {
          font-size: 1rem;
          font-weight: 500;
          color: $va-black-50;
          margin-bottom: 1rem;
        }
        .promo-code {
          display: flex;
          align-items: center;
        }
        input[type="text"] {
          margin: 0;
          height: 2.5833rem;
          text-transform: uppercase;
        }
        input[type="text"].valid {
          background: url(../../img/check.svg);
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-color: white;
        }
        input[type="text"]:before {
          content: " ";
          width: 16px;
          height: 16px;
          position: absolute;
          right: 0;
          top: 50%;
        }
        a {
          display: block;
          color: $va-blue-100;
          font-size: 1.16rem;
          font-weight: 700;
          line-height: 1.33rem;
          padding-left: 1.3333rem;
          cursor: pointer;
        }
      }
    }
  }
}

// @media (max-width: 768px) {
//   main {
//     padding: 60px 20px;
//     .summary {
//       padding: 0 20px;
//     }
//     form .billing {
//       grid-template-columns: 1fr;
//       padding: 30px;
//       .promo {
//         grid-template-columns: 1fr;
//         input#promo {
//           width: 100%;
//           box-sizing: border-box;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 500px) {
//   main form label input:not(#counter) {
//     box-sizing: border-box;
//     width: 100%;
//   }
// }
