@import './vars';


body {
  //background-color: #061F61;
}

.va-page {
  margin: 0 $comps-tab-width;
}

.font-place-holder {
  font-family: $font-family-robo;
  font-size: $font-size-small;
  font-style: $font-style-default;
  font-weight: $font-weight-default;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  color: $va-grey-75;
}

.font-blue-modal-header {
  font-family: $font-family-robo;
  font-size: $font-size-medium;
  font-style: $font-style-default;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-weight: $font-weight-default;
  color: $va-blue-100;
}

.font-table-header {
  font-family: $font-family-robo;
  font-size: $font-size-medium;
  font-style: $font-style-default;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-weight: $font-weight-default;
}

.font-valign {
  margin-top: $line-height-default/2;
}

.container-buttons {
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem;
}


.svg-button {
  display:inline-block;
  background-repeat: no-repeat;
  background-color: transparent;
  border:none;
}


.roboto-bold-36 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 16px;
  color: $va-black-100;
}


.roboto-regular-24 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 16px;
  color: $va-black-100;
}

.roboto-regular-18 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  color: $va-black-100;
}

.roboto-bold-16 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: $va-black-100;
}

.roboto-bold-18{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  color: $va-black-100;
}


.roboto-gold-16 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: black;
  //background-color: $color3;
  //border-radius: 4px;
  //padding-top: 5px;
  //padding-bottom: 5px;
  //padding-left: 10px;
  //padding-right: 10px;
  //width: 100%;
}

.roboto-regular-12 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $va-black-100;
}

.roboto-blue-underlined-12-inline {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: $va-black-100;
  display: inline-block;
}

.roboto-regular-14-padding {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $va-black-100;
  padding-left: 5px;
}

.roboto-regular-14 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $va-black-100;
}

.roboto-medium-18 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #C4C4C4;
}

.roboto-thin-16 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #C4C4C4;
}


.roboto-light-12 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #C4C4C4;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.blur {
  filter: blur(4px);
  pointer-events: none;
}

.hidden {
  display: none;
}


.tool-tip-container{
  display: inline-block;
  margin: 5px;
}

.info-image {
  cursor: pointer;
}

.tool-tip {
  background-color: white !important;
  color: black !important;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  opacity: 1.2 !important;
  text-align: left;
  padding: 5px;
}


