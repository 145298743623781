@import '../../style/vars.scss';
@import 'bootstrap';

.landing-page {

  .landing-row{
    margin: auto;
    max-width: map-get($grid-breakpoints, "xxl");
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .padded-bottom{
    padding-bottom: 40px;
  }

  .left-padding{
    padding-left: 30px;
  }

  .right-padding{
    padding-right: 30px;
  }

  .text-align-right {
    text-align: right;
  }

  button {
    font-size: 16px;
  }

  .header {
    $header-padding: 70px;
    text-align: center;
    background-image: url("../../img/background_img_blue.png");

    .informational-header {
      padding-top: $header-padding;
    }

    .informational-header {
      margin: 0;
      color: $va-secondary-100;
      padding-bottom: 0;
    }

    p {
      width: 95%;
      color: white;
      padding-bottom: $header-padding;
      padding-top: 40px;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .big-button,
  .small-button {
    height: 50px;
    margin-bottom: 10px;
  }

  .big-button {
    width: 250px;
  }

  .round-button {
    border-radius: 30px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    height: 60px
  }



  .small-button {
    width: 200px;
  }

  .button-center {
    margin: auto;
  }

  .button-right {
    margin-left: auto;
    margin-right: 20px;
  }

  .button-left {
    margin-right: auto;
    margin-left: 20px;
  }

  .free-text {
    text-decoration: underline;
  }

  .landing-image {
    width: 100%;
    min-width: 300px;
    padding-bottom: 20px;
  }

  .landing-logo {
    width: 25%;
    min-width: 300px;
    margin: auto;
  }

  .tp-logo {
    width: 100%;
    min-width: 300px;
    margin-left: auto;
    padding-bottom: 70px;
    padding-top: 0px;
    float: right;

  }

  .float-container{
    overflow: hidden;
  }

  .logo-background {
     background-repeat: no-repeat;
     background-image: url('../../img/landingpage_figures/VABIGTRANSPARENTLOGO.png');
     background-size: 625px;
     background-position: right center;

  }
  .left-right-margins{
    margin-left: 20px;
    margin-right: 20px;
  }

  .filter-preview-space {
    height: 200px;
  }

  .landing-container {
    > .row {
      padding-bottom: 100px;
      padding-top: 100px;
    }


    > .row:nth-child(odd) {
      background-color: #ffffff;
    }

    > .row:nth-child(even) {
      background-color: #f4f7ff;
    }
  }

  .small-padding{
    padding-bottom: 25px !important;
    padding-top: 25px !important;
    }

  .feedback-text {
    text-align: center;
    line-height: 30px;

    hr {
      width: 200px;
      margin: auto;
      background-color: $va-secondary-100;
      opacity: 1;
      height: 10px;
    }
  }
}
