@import '../../style/vars';

.billing-container {
  .billing-nav {
    margin-bottom: 3rem;
    ul {
      list-style: none;
      border-bottom: 1px solid $va-grey-35;

      li {
        display: inline-block;
        font-size: 1.3333rem;
        font-weight: 700;
        .selected {
          border-bottom: 3px solid $va-accent-100;
        }
        a {
          display: block;
          padding: 0 1.8rem .5rem;
          text-decoration: none;
          color: black;
        }
      }
    }
  }
  .billing-history {
    h2 {
      font-size: 16px;
      margin-bottom: 1rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $va-grey-35;
    }
    .billing-date {
      margin-bottom: 4rem;
      h2 {
        color: black;
        font-size: 16px;
      }
      p {
        font-size: 1.3333rem;
      }
    }
    .method {
      margin-bottom: 4rem;

    }
    .history {
      .item {
        display: grid;
        grid-template-columns: 1fr 1fr 4fr;
        width: 100%;
        margin-left: 1rem;
        margin-bottom: 1rem;
        font-size: 1.1667rem;
        a {
          margin-right: 1.5rem;
          text-decoration: none;
          text-align: right;
          font-weight: 500;
          color: $va-blue-100;
        }
        p:first-child {
          margin-right: 2rem;
        }
      }

    }
  }
  .team-management {
    .admin-text {
      margin-bottom: 1.5rem;
      font-size: 14px;
    }
    button {
      margin-bottom: 4rem;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 4rem;
      thead {
        border-bottom: 1px solid $va-grey-35;
        th {
          padding-bottom: 2rem;
          font-size: 16px;
        }
      }
      tbody {
        td {
          padding-top: 1rem;
          font-size: 14px;
        }
        td:last-child {
          color: $va-blue-100;
          font-weight: 500;
        }
        td:not(:last-child) {
          padding-right: 2.5rem;
        }
      }
    }

  }
}
