@import '../../style/vars';

  .filter-flap {
    //display:grid;
    align-items: center;
    //grid-template-columns: [start] .25fr[middle] max-content[end];
    //width: 11.33rem;
    //height: 2.66rem;
    background-color: $container-background-color;
    border: none;
    //font-family: Roboto;
    //font-size: 1.16rem;
    //color: $va-grey-50;
    //width: 100%;
    //vertical-align: center;
    //line-height: 2.66rem;

    &:hover {
      cursor: pointer;
    }

 }


