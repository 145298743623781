@import '../../style/vars';
@import '../../style/style';

.comps-page-saved-comps-groups{
  grid-area: saved-comps-groups;
  grid-column: left-component-start/left-component-end;
  grid-row: row-component-start/row-component-end;
  padding: 0 1rem 1rem 0;
}

.comps-page-selected-comps-group {
  grid-area: selected-comps-group;
  grid-column: left-component-end/-1;
  grid-row: row-component-start/row-component-end;
  padding: 0 0 1rem 1rem;
}

.comps-page-table {
  grid-area: table;
  grid-column-start: left-component-start;
  grid-column-end: -1;
  grid-row-start: table-start;
  grid-row-end: -1;
  padding: 0 1rem 0 0;
}

.comps-page {
  display: grid;
  grid-template-columns: [left-component-start] 2fr[left-component-end] 1fr;
  grid-template-rows: [row-component-start] 300px[row-component-end table-start] auto;
  height: 100%;
  position: relative;
}
