@import "../../style/vars";

$color2: #4ca9ee;
$color3: #eead4c;
$color4: #eef9fc;

.signup-style {
  background-image: url("../../img/background_img_blue.png");
  height: 100%;
  background-size: cover;
  color: $va-white-100;

  .subscription-access-text {
    margin-top: 5rem;
  }

  .image-wrap{
    height: 100%;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin: 1em 0;

    span{
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      max-height: 400px;
      max-width: 400px;
    }
  }


  .aside-style{
    text-align: center;
    height: 100%;
  }

  .pay-text {
    text-align: left;
    font-size: 1.67rem;
  }

  .create-account-button {
    width: 288px;
    height: 61px;
    font-size: 20px;
  }

  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.09em;
    text-align: left;
  }

  h3 {
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 35px;
    letter-spacing: 1.5px;
    display: block;
    text-align: center;
  }

  .feature {
    display: inline-block;
    text-align: center;
    line-height: 2rem;
    height: 100%;
    width: 300px;

    h4, p {
      margin-top: 10px;
      maring-bottom: 10px;
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      color: $va-grey-25;
    }
  }
}

.auth-container-positioning {

  .create-account-button {
    margin-top: 2px;
    margin-right: 550px;
    width: 288px;
    height: 61px;
    font-size: 20px;
  }

  .pay-text {
    text-align: left;
    padding-left: 0 !important;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    margin: 0 !important;
  }

  min-height: 100vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 20;
}

.backgroundimg {
  z-index: 0;
  opacity: 70%;
  position: absolute;
  width: 100%;
}

.feature-icon {
  margin: auto;
}

.signup-opacity {
  opacity: 70%;
  background-color: #061F61;
}

@media (max-width: 1100px) {
  #wrapper {
    grid-template-columns: 1fr;

    aside {
      h2 {
        max-width: initial;
      }

      .features {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

@media (max-width: 768px) {
  #wrapper {
    aside {
      .features {
        grid-template-columns: 1fr;
      }
    }
  }
}
