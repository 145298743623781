@import '../../style/style';

.ranks-table {
  display: grid;

  .ranks-table-cards {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
    overflow: auto;

    .ranks-table-card {
      table.va-table {
        box-shadow: 0 0 0 3px $va-blue-100, $container-box-shadow;
        border-radius: $container-border-radius;
        thead {
          th {
            background-color: $va-blue-35;
            text-align: center;
          }
        }
      }
    }

    @media (max-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1150px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 845px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

}
