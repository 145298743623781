@import '../../style/vars';

.new-user {
  max-width: 700px;
  input {
    width: 200px;
  }
  input, select {
    margin-bottom: 0;
  }
  .select-plan {
    position: relative;
    select:invalid {
      color: $va-grey-50;
      option {
        color: black;
      }
    }
  }
  input[type='submit'] {
    width: max-content;
    background: none;
    padding: 0;
    position: relative;
    top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: $va-blue-100;
    text-align: center;
    cursor: pointer;
  }
  input[type='submit']:disabled {
    color: $va-grey-50;
    cursor: default;
  }
}
