.acct-info-container {
  .new-password {
    display: flex;
  }

  .new-password label[for="new-password"] {
    margin-right: 4.6667rem;
    position: relative;
  }

  h3 {
    margin-bottom: 2.6667rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  button {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }
}
