@import '../../style/style';
@import '../../style/vars';
@import '../../style/mixin';

.table-tab {
  border-radius: $container-border-radius $container-border-radius 0 0;
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  border-color: $color3 $color3 $va-white-100 $color3;
  border-style: solid;
  border-width: 1px;
  border-bottom: None;
  box-shadow: 0px 1px 0 white;

}

.tab-inactive {
  background-color: $va-white-100;
}

.tab-active {
  background-color: $color3;
}

.table-tab-text {
  @include absolute-center;
}
