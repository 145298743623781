@import '../../style/vars';

.pad-lock-wrap {
  position: relative;
  width: 100%;
  height: 100%;

  .pad-lock-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: (-50%, 50%);

    .va-modal {
      width: 300px;
      height: 250px;

      .children-wrap {
        align-items: center;
        justify-items: center;
        display: grid;
        grid-template-rows: 114px 50px 100px;
        width: 100%;
        height: 100%;
      }
    }

    .subscribe-text {
      font-size: 16px;
      margin: 10px;
      text-align: center;
    }

    .subscribe-link {
      font-size: 20px;
      color: $va-secondary-100;
      text-decoration-line: underline;
    }
  }
}


