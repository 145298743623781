@import '../../style/style';

.sort-arrows {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & .sort-arrow {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  & button {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
  }
}
