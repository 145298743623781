@import '../../style/vars';
@import 'bootstrap';

.size-premiums-page {
  .premium-group {
    .nav-item:last-child {
      border-bottom: 1px solid black;
    }

    &:last-child {
      .nav-item {
        border-bottom: none;
      }
    }
  }

  .full-height {
    height: 100%;
  }

  .table-container {
    min-height: 300px;
  }

  .left-side-nav-container {
    @include media-breakpoint-up(lg) {
      border-right: 1px solid #000;
    }

    ul {
      list-style: none;

      li {
        min-width: 200px;
        font-size: 14px;
        font-weight: 500;

        .selected {
          color: $va-accent-100;
        }

        a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}
