@import '../../style/style';

// Override Bootstrap Checkbox colors
.form-check-input:checked{
  background-color: $color2 !important;
  border: 0;
  border-radius: 0;
}
.form-check-input:focus, .label::after, label.form-check-label:focus, .form-check-input::after, .form-check-input:not(:disabled):not(.disabled):active:focus {
  color: black;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem green !important;
  border-radius: 0;
}




