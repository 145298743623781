@import '../../style/vars';

.card {
  position: relative;
  background-color: $container-background-color;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  margin-bottom: 5rem;
  padding: 2.5rem;
  h3 {
    font-size: 1.5rem;
  }
  p {
    font-family: $font-family-robo;
    color: $va-grey-75;
    margin-bottom: 0;
  }
  .description {
    display: inline-block;
    margin-top: 0;
    padding-left: 1rem;
  }
  .document-list {
    margin-top: 1rem;
    padding-left: 2.5rem;
    img {
      height: 1.75rem;
      padding-right: 15px;
    }
    .document {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .name {
        color: #000;
      }
      a {
        color: #000
      }
    }
  }
}
