@import "../../style/vars";

.category-criteria {
  //display: grid;
  //grid-template-columns: [start] .25fr[column-1] 2fr[end];
  //grid-auto-rows: minmax(min-content, max-content);
  //justify-items: left;
}

.category-toggle{
  display: inline-block;
  width: 30px;
  color: #eead4c;
  font-weight: bold;
}

.category-name {
  display: inline-block;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: $va-blue-100;
  margin-bottom: 5px;
}

