@import '../../style/style';
@import '../../style/vars';

.selected-comps-groups-container {
  padding: 10px;
  display: grid;
  grid-template-rows: min-content auto min-content ;

  .ticker-container {
    overflow:auto;

    .container-save {
      position:sticky;
      top:0;
      background-color: $container-background-color;
    }
  }
}

.selected-comps-group {
  display: grid;
  grid-template-columns: [remove-button] 1fr[ticker] 1fr [remove-button] 1fr[ticker] 1fr[end];
  justify-items: left;
  align-items: center;
  padding: 1rem;
  width: 100%;
  min-height: 50%;
}




.remove-button {
  grid-column: [remove-button]/[ticker];
  min-width: 75px !important;
}

.ticker {
  grid-column-start: [ticker]/[end];
}

.selected-comps-group-wrap {
  display: grid;
  grid-template-rows: auto 30px;
  height: 100%;
  width: 100%;
}


.empty-comps-group-text {
  align-self: center;
  justify-self: center;
  flex-grow: 3;
  position: relative;

  & span {
    text-align: center;
    white-space: nowrap;
  }
}

.selected-comps-button {
  justify-self: end;
  padding-top: 1rem;
}


.h_line_selected_comps {
  width:95%;
  height:1px;
  background-color: $va-blue-100;
  margin: 10px 0px 0px 5px;
}

