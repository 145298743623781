@import '../../style/style';

.comps-table {

  .comps-table-input {

    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;

    .input-wrap {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  .warning-message {
    color: orange;
    font-size: 15px;
    padding: 1rem 1rem;
  }
}
