@import '../../style/vars';


.picker-list-container {
  background-color: $va-white-100;
  border-radius: $container-border-radius;
  border: 1px solid $va-blue-100;
  margin: 0 1.66rem;
  overflow: auto;
  max-height: 13.34rem;
}

.picker-list {
  border-top: 1px solid $va-blue-100;
  display: grid;
  grid-template-columns: [start] .25rem[picker-level-1] 2rem[picker-level-2] 2rem[picker-level-3] max-content[end];
  align-items: center;
  overflow:auto;
}

.picker-level-1 {
  grid-column: picker-level-1/end;
}

.picker-level-2 {
  grid-column: picker-level-2/end;
}

.picker-level-3 {
  grid-column: picker-level-3/end;
}

.picker {
  display: grid;
  grid-template-columns: [start] 2rem min-content min-content .25rem;
  align-items: center;
  justify-items: left;
}

.picker-arrow {
  grid-column: 1/2;
}

.picker-checkbox {
  grid-column: 2/3;
}

.picker-label {
  grid-column: 3/4;
  white-space: nowrap;
  padding: 0 1rem;
}
