@import '../../style/vars';

.saved-filter-container {
  background-color: $container-background-color;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  height: 100%;
}

.saved-filter-header {
  min-height: 4.16rem;
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
  align-items: center;
}

.saved-filters {
  display: grid;
  padding: 1rem 3rem 0rem 3rem;
  grid-template-columns: [start-date-created] 1fr[end-date-created start-name] 1fr[start-actions];
  align-items: center;
  justify-items: left;
}

.filter-date {
  grid-column: start-date-created/end-date-created;
}

.filter-name {
  grid-column: start-name/start-actions;
}

.filter-actions {
  grid-column: start-actions/end;
}

.saved-filter-buttons {
  & button {
    width: 3rem;
    text-align: left;
    padding: 0;
  }
}
