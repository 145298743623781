@import 'bootstrap';

:root {
  --amplify-primary-color: #061F61;
  --amplify-primary-tint: #59C2E3;
  --amplify-primary-shade: #E6EAF3;
  --amplify-secondary-color: #061F61;
  //--amplify-secondary-tint: #31465f;
  //--amplify-secondary-shade: #1F2A37;
  --amplify-red: #2E2E2E;
  --amplify-font-family: Roboto;
  --amplify-text-sm: 13.5px;
  --amplify-text-xs: 13px;
  --amplify-text-md-sub: 13px;
  --amplify-secondary-tint: #59C2E3;
  --footer-font-size: 12px;
}

amplify-authenticator {
  --container-height: 100%;
}

@include media-breakpoint-down(sm) {
  amplify-authenticator {
    --container-display: block;
  }
}

amplify-toast {
  --amplify-white: black;
}

