@import '../../style/vars';

.input-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 130px;
  border: 1px solid $datepicker-border-color;
  border-radius: $container-border-radius;

  .picker-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    gap: 1rem;
    padding: 2px;
  }
}

