@import './style/vars';
@import './style/style';

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .app-nav {
    position: relative;
    z-index: $z-index-nav-bar;
  }

  .app-page {
    flex: 1 0 auto;
  }

  .footer-wrapper{
    position: relative;
    .footer {
      flex-shrink: 0;
    }
  }
}

* {
  box-sizing: border-box;
  font-family: Roboto;
}






.informational-page {
  box-sizing: border-box;
  font-family: $font-family-robo;
}

.informational-h1 {
  font-size: 32px;
  color: $va-title-blue;
  font-weight: 500;
  margin-bottom: 25px;
  font-family: "Avenir Next";
  line-height: 43.71px;
}

.contact-us {
  font-size: 20px;
  color: $va-title-blue;
  font-weight: 600;
  font-family: "Avenir Next";
  line-height: 36.06px;
}

.informational-value-prop {
  font-family: "Avenir Next";
  font-size: 42px;
  color: $va-title-blue;
  font-weight: 600;
  text-align: center;
  line-height: 57.37px;
}

.informational-header {
  padding-left: 8.75rem;
  padding-right: 8.75rem;
  padding-top: 4.0rem;
  padding-bottom: 4.0rem;
}

.informational-header-in-container {
  padding-top: 4.0rem;
  padding-bottom: 4.0rem;
}

.card-style {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  padding: 2.5rem;
}


.informational-p {
  line-height: 3.0rem;
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 22px;
  color: black;
}

.emphasis{
  line-height: 3.0rem;
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 22px;
  color: $va-title-blue;
  font-style: italic;
}

.informational-p-center{
  font-size: 2.0rem;
  line-height: 3.0rem;
  text-align: center;
  margin:0;
}

.informational-main {
  padding: 0 8.75rem;
}

.inline-bold {
  font-weight: bold;
}

.blue-link {
  color: #061F61;
}

.white-link {
  color: white;
}

.black-link {
  color: black;
  text-decoration: underline;
}

.centered {
  margin: auto;
}

.list-container{
  //text-align:center;
  margin: 20px;
}
.step-by-step-list{
  display: inline-block;
  text-align: left;
}

.nav-round-button {
    border-radius: 30px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  }

