@import '../../style/vars';
@import '../../style/style';

.signup-page-coupon,
.create-account {
  align-items: center;
  justify-items: center;
  margin-top: 10px;
  background-color: $va-white-100;
  width: 100%;
  height: min-content;
  max-width: 50rem;
  border-radius: .5rem;
  margin-top: 4rem;

  h1 {
    font-size: 2rem;
    line-height: 3.5rem;
    font-weight: 300;
    color: $va-blue-100;
    margin-top: 3rem;
    text-align: center;
  }

  button {
    font-size: 14px;
    width: 200px;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
 }

  .coupon-form {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 250px;
    grid-template-rows: 8rem 8rem;


    .coupon-form-errors {
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      justify-self: center;
      height: 20px;
      color: red;
      margin: 20px;

      &.hidden {
        display: none;
      }
    }
  }

  .privacy-policy {
    border: 1px solid black;
    border-radius: 5px;
    padding: 1rem;
    margin: 4rem;
    height: 18.25rem;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 2.66rem;
    font-family: Open Sans;
    h3 {
      font-size: 1.16rem;
    }
    p {
      font-size: .75rem;
    }
  }
}



.create-account {
  grid-template-rows: 100px 35px 220px 300px 50px;
  padding: 0px 30px 0px 30px;
  .error-message {
    grid-column: 1/-1;
    color: red;
    margin: 0 100px 20px 100px;
  }
  .create-account-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: start;
    justify-items: center;
    grid-template-rows: 7rem;

    button.get-started {
      grid-column: 1/-1;
      align-self: center;
      justify-self: center;
      white-space: nowrap;
      margin: 20px 0;
    }

    .privacy-policy {
      grid-row: 3/4;
      grid-column: 1/-1;
      width: 40rem;
    }

    .agree {
      display: grid;
      grid-template-columns: 20px auto;
      align-items: center;
      grid-row: 4/5;
      grid-column: 1/-1;
      grid-gap: 12px;

      input {
        width: 20px;
      }
    }
  }
}

.image-logo {
  z-index: -1;
  position: absolute;
  width: 70vh;
  right: 200px;
  top: 15rem;
  height: 20rem;
}
