@import '../../style/style';
@import '../../style/vars';

.va-container {
  background-color: $container-background-color;
  box-shadow: $container-box-shadow;
  border-right: $container-border-radius;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: .5rem;
}
