@import '../../style/vars';
@import 'bootstrap';

.documentation-page {
  @include media-breakpoint-up(md) {
    .container {
      max-width: map-get($grid-breakpoints, md)
    }
  }

  .documentation-page-categories {
    display: flex;
    justify-content: space-between;
  }

  .doc-page-header {
    margin-bottom: 0.25rem !important;
  }
}
