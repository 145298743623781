@import "../../style/vars";
$color2: #4ca9ee;
$color3: #eead4c;
$color4: #eef9fc;

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



#wrapper {
  min-height: calc(100vh - 75px);
  font-family: Roboto;
  background: linear-gradient(to top right, transparent 50%, $va-blue-100 50%) left top/134vw,
              linear-gradient(180deg, #4ca9ee16, #59C2E316),
              $va-blue-100;
  padding: 4.5rem 8rem;
  #main {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 1rem;
    main {
      padding-right: 5rem;

      h1 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: $color2;
        margin-bottom: 4.5rem;
      }
      h3 {
        font-size: 2.67rem;
        line-height: 1.33rem;
        margin-bottom: 3.17rem;
        color: $va-white-100;
      }
    }
    aside {
      color: $va-white-100;
      padding-top: 10rem;
      .logo {
        position: absolute;
        top: 12rem;
        right: 4rem;
      }
      h2 {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 2.67rem;
        letter-spacing: 1.5px;
        & #a {
          font-size: 2.5rem;
        }
        & #m {
          font-size: 2.5rem;
        }
      }
      ul {
        padding-left: .75rem;
        font-size: 1.4rem;
        line-height: 2rem;
        li {
          margin: 1.5rem 1rem;
          padding-left: 1rem;
        }
      }
    }
  }
}

.plan-options {
        .option {
          background-color: $va-white-100;
          border: $color2 solid 3px;
          border-radius: 5px;
          max-width: 300px;
          height: 300px;
          padding: 2.6rem 1rem;
          text-align: center;
          z-index: 1;
          position: relative;
          margin: 20px auto;
          .title {
            padding: 20px 5px;
            font-size: 1.67rem;
            line-height: 1.33rem;
            margin-bottom: 1.3rem;
            font-weight: bold;
            color: $va-blue-100;
          }
          .price {
            font-size: 1.33rem;
            color: black;
            font-weight: bold;
            margin-bottom: 1rem;
          }
          .subtitle {
            font-size: 1.33rem;
            color: black;
            margin-bottom: 1rem;
          }
          .trial {
            font-size: 1.5rem;
            color: black;
            line-height: 3.0rem;
          }
          .tag {
            height: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 2rem;
          }
          .get-started {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translate(-50%, 10%);
            & button {
              outline: none;
              background: $color3;
              color: black;
              font-weight: 500;
              box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }
        .option.best-value {
          position: relative;
          .price {
            color: $va-black-100;
          }
          .tag {
            color: $va-grey-75;
            font-weight: bold;
            font-size: 1.1rem;
            span {
              color: #0E1F61;
            }
          }
        }
        .option.best-value::before {
          position: absolute;
          top: -22px;
          left: 50%;
          transform: translateX(-50%);
          content: 'Best Value';
          background-color: $color3;
          margin-bottom: 2rem;
          padding: 10.25px;
          width: 75%;
          border: $color3 solid 3px;
          border-radius: 5px;
          color: black;
          font-size: 1.33rem;
          font-weight: bold;
        }
      }

.plan-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
