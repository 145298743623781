@import '../../style/vars';

input {
  border: none;
  outline: none;
  width: 80px;
}

.react-datepicker__tab-loop {
  //z-index: $z-index-calendar;
}


.react-datepicker__today-button {
  color: black !important;
}