@import '../../style/vars';

$spinner-color: #33f1ff;
$gold: #eead4c;

.async-loader{
  font-size: 10px;
  margin: 12px auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $va-blue-100;
  background: -moz-linear-gradient(left, $va-blue-100 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $va-blue-100 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $va-blue-100 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $va-blue-100 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $va-blue-100 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.async-loader.inline{
  display:inline-block
}

.async-loader.gold {
  font-size: 10px;
  margin: 12px auto;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: $gold;
  background: -moz-linear-gradient(left, $gold 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $gold 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $gold 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $gold 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $gold 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.async-loader.gold:before {
  width: 50%;
  height: 50%;
  background: $gold;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.async-loader:before {
  width: 50%;
  height: 50%;
  background: $va-blue-100;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}


.async-loader:after {
  background: $va-table-stripe;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  70% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
