@import '../../style/style';

.filter-search-bar {
  display: flex;
  flex-direction: row;
  box-shadow: inset 0px 0px 2px #000000;
  border-radius: 4px;
  margin-bottom: 10px;

  & input {
    border: none;
    width: 99%;
    margin: .5rem;
  }
}
